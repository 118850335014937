import {
  UPDATE_ROOM_STATE,
  UPDATE_ROUND_STATE,
  UPDATE_USER_STATE,
  UPDATE_ENTRIES_STATE,
  UPDATE_MINIGAMES_STATE,
  UPDATE_SESSION_STATE,
  UPDATE_DRAW_STATE,
  UPDATE_NEW_ENTRY_STATE,
  UPDATE_FILLING_IN_BLANKS_STATE,
  UPDATE_CRITIC_STATE,
  UPDATE_ROUND_INFO_STATE, 
  UPDATE_MUSIC_STATE} from "./ActionTypes";

const stateReducer = (state, action) => {
  switch (action.type) {
    case UPDATE_ROOM_STATE:
      return { ...state, room: action.payload };
    case UPDATE_ROUND_STATE:
      return { ...state, round: action.payload };
    case UPDATE_USER_STATE:
      return { ...state, user: action.payload };
    case UPDATE_ENTRIES_STATE:
      return { ...state, entries: action.payload };
    case UPDATE_MINIGAMES_STATE:
      return { ...state, minigames: action.payload };
    case UPDATE_SESSION_STATE:
      return { ...state, session: action.payload };
    case UPDATE_DRAW_STATE:
      return { ...state, draw: action.payload };
    case UPDATE_NEW_ENTRY_STATE:
      return { ...state, newEntry: action.payload };
    case UPDATE_MUSIC_STATE:
      return { ...state, music: action.payload };
    case UPDATE_FILLING_IN_BLANKS_STATE:
      return { ...state, fillingInBlanks: action.payload };
    case UPDATE_CRITIC_STATE:
      return { ...state, critic: action.payload };
    case UPDATE_ROUND_INFO_STATE:
      return { ...state, roundInfo: action.payload };
    default:
      return state;
  }
};

export default stateReducer;
