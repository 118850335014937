import { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Howl } from 'howler';

/**
 *  Component which plays music in the background.
 *  - Includes set of tracks for the lobby and set of tracks for during the rounds
 *  - Only played by default for the room owner.
 *  - Reacts to room / round changes and plays the appropriate music.
 *  - Reacts to mute state changes and mutes/unmutes the music.
 */
export function Music() {
    const TrackType = {
        Lobby: 0,
        Round: 1
    };
    const { isMuted } = useSelector(state => state.music);

    const [allTracks, setAllTracks] = useState(null);
    const [lobbyTracks, setLobbyTracks] = useState(null);
    const [roundTracks, setRoundTracks] = useState(null);
    const prevCurrentRoundIdRef = useRef(null);
    const prevRoomIdRef = useRef(null);
    const room = useSelector(state => state.room);

    useEffect(() => {
        const lobbyTracksFiles = [
            "beltane-celtic-firedance-music-613.mp3",
            "Fiddle-De-Dee_by_SilvermanSound_Traditional_Irish_Music_No_Copyright_128kbps.mp3",
            "informa-irish-tavern-162997.mp3",
            "irish-184500.mp3",
        ];
        const roundTracksFiles = [
            "irish-153193.mp3",
            "irish-punk-rebel-211985.mp3",
            "the-higher-land-ten-string-story-main-version-34571-03-46.mp3",
            "unite-the-clans-paulo-kalazzi-main-version-02-07-2566.mp3"
        ];

        const createHowl = (src) => new Howl({ src: [`/audio/${src}`] });
        const lobbyTracks = lobbyTracksFiles.map(createHowl);
        const roundTracks = roundTracksFiles.map(createHowl);

        // When a track ends, play the next track in the list.
        const setupLoopTracklist = (tracks) => {
            for (const track of tracks) {
                track.off('end');
                track.on('end', () => {
                    const nextTrackIndex = (tracks.indexOf(track) + 1) % tracks.length;
                    const nextTrack = tracks[nextTrackIndex];
                    nextTrack.play();
                });
            }
        };

        setupLoopTracklist(lobbyTracks);
        setupLoopTracklist(roundTracks);

        setAllTracks([...lobbyTracks, ...roundTracks]);
        setLobbyTracks(lobbyTracks);
        setRoundTracks(roundTracks);
    }, []);

    // Update the music when leaving and entering the round.
    useEffect(() => {
        // Wait for the tracks to load.
        if (!allTracks || !roundTracks || !lobbyTracks) {
            return;
        }

        // When the round ends, play the lobby music.
        if (room && prevCurrentRoundIdRef.current && !room.currentRoundId) {
            const syncId = room.roomId + (room.roundNumber || 0);
            playTrack(TrackType.Lobby, syncId);
        }

        // When the round starts, play the round music.
        if (room && !prevCurrentRoundIdRef.current && room.currentRoundId) {
            playTrack(TrackType.Round, room.currentRoundId);
        }

        prevCurrentRoundIdRef.current = room?.currentRoundId;
    }, [room?.currentRoundId, allTracks, lobbyTracks, roundTracks]);

    // Update the music when joining the room.
    useEffect(() => {
        // Wait for the tracks to load.
        if (!allTracks || !roundTracks || !lobbyTracks) {
            return;
        }

        // If we're in a round, don't play the lobby music.
        if (room?.currentRoundId) {
            return;
        }

        // When joining a room, play the lobby music.
        if (room && !prevRoomIdRef.current && room.roomId) {
            const syncId = room.roomId + (room.roundNumber || 0);
            playTrack(TrackType.Lobby, syncId);
        }

        prevRoomIdRef.current = room?.roomId;
    }, [room?.roomId, allTracks, lobbyTracks, roundTracks]);

    // Mute the music when the mute state changes.
    useEffect(() => {
        if (allTracks) {
            allTracks.forEach(track => track.mute(isMuted));
        }
    }, [allTracks, isMuted]);

    /**
     * Play the next track in the given track list.
     * 
     * @param {*} trackType Either Lobby or Round, lobby music is more chilled.
     * @param {*} syncId    An integer that cycles the tracklist in a synchronised way between all clients.
     *                      For example, the room ID + round number.
     */
    function playTrack(trackType, syncId) {
        allTracks.forEach(track => track.stop());
        const tracks = trackType === TrackType.Lobby ? lobbyTracks : roundTracks;
        const trackIndex = syncId % tracks.length;
        const nextTrack = tracks[trackIndex];
        nextTrack.play();
    }

    return null;
};