import {
    UPDATE_ROOM_STATE,
    UPDATE_ROUND_STATE,
    UPDATE_USER_STATE,
    UPDATE_ENTRIES_STATE,
    UPDATE_MINIGAMES_STATE,
    UPDATE_SESSION_STATE,
    UPDATE_DRAW_STATE,
    UPDATE_NEW_ENTRY_STATE,
    UPDATE_MUSIC_STATE,
    UPDATE_FILLING_IN_BLANKS_STATE,
    UPDATE_CRITIC_STATE,
    UPDATE_ROUND_INFO_STATE} from "./ActionTypes";

export const updateRoomState = roomState => ({ type: UPDATE_ROOM_STATE, payload: roomState });
export const updateRoundState = roundState => ({ type: UPDATE_ROUND_STATE, payload: roundState });
export const updateUserState = userState => ({ type: UPDATE_USER_STATE, payload: userState });
export const updateEntriesState = entriesState => ({ type: UPDATE_ENTRIES_STATE, payload: entriesState });
export const updateMinigamesState = minigamesState => ({ type: UPDATE_MINIGAMES_STATE, payload: minigamesState });
export const updateSessionState = sessionState => ({ type: UPDATE_SESSION_STATE, payload: sessionState });
export const updateDrawState = drawState => ({ type: UPDATE_DRAW_STATE, payload: drawState });
export const updateNewEntryState = newEntryState => ({ type: UPDATE_NEW_ENTRY_STATE, payload: newEntryState });
export const updateFillingInBlanksState = fillingInBlanksState => ({ type: UPDATE_FILLING_IN_BLANKS_STATE, payload: fillingInBlanksState });
export const updateCriticState = criticState => ({ type: UPDATE_CRITIC_STATE, payload: criticState });
export const updateRoundInfoState = roundInfoState => ({ type: UPDATE_ROUND_INFO_STATE, payload: roundInfoState });
export const updateMusicState = musicState => ({ type: UPDATE_MUSIC_STATE, payload: musicState });

