import { configureStore } from '@reduxjs/toolkit'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
import rootReducer from "./RootReducer";
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2'
import LogRocket from 'logrocket';
import { createLogger } from 'redux-logger'
import { UPDATE_MINIGAMES_STATE } from './ActionTypes';

const persistConfig = {
  key: 'root',
  storage,
  debug: true,
  log: true,
  stateReconciler: autoMergeLevel2
}

const actionTransformer = action => {
  return JSON.stringify(action);
}
const logger = createLogger({
  diff: true,
  actionTransformer,
  predicate: (_, action) => action.type != UPDATE_MINIGAMES_STATE
});

const persistedReducer = persistReducer(persistConfig, rootReducer)

const rehydrateLogger = (storeAPI) => (next) => (action) => {
  if (action.type === 'persist/PERSIST') {
    console.log('State is persisting to storage');
  }
  if (action.type === 'persist/REHYDRATE') {
    console.log('State rehydrated from storage:', action.payload);
  }
  return next(action);
};

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ['persist/PERSIST', 'persist/REHYDRATE']
      }
    }).concat(LogRocket.reduxMiddleware(), rehydrateLogger, logger),
});

export const persistor = persistStore(store)
export default store;
